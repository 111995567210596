import { Directive, ElementRef, AfterViewInit, Input, OnDestroy, Component, HostBinding, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { TooltipPopper } from './tooltipPopper';
import { EmitterService, LAYOUT_CHANGED } from 'ngx-myia-core';
import { Observable, Observer, of } from 'rxjs';

export class TooltipTemplateBase {
  element: Observable<any>; // nativeElement

  protected elementObserver: Observer<any>;

  constructor(element: ElementRef) {
    this.element = new Observable((observer: Observer<any>) => {
      this.elementObserver = observer;
      if (element.nativeElement) {
        this.elementObserver.next(element.nativeElement);
        this.elementObserver.complete();
      }
    });
  }
}

@Component({
  selector: 'ngx-myia-tooltip-template-plain',
  template: `
    <div class="tooltip__inner">
      <ng-content></ng-content>
    </div>
  `
})
export class TooltipTemplatePlainComponent extends TooltipTemplateBase implements AfterViewInit {

  @HostBinding('class.tooltipJs') hostClass = true;

  constructor(protected _element: ElementRef) {
    super(_element);
  }

  ngAfterViewInit() {
    // hide tooltip initially
    this._element.nativeElement.classList.add('hidden');
    if (this.elementObserver) {
      this.elementObserver.next(this._element.nativeElement);
      this.elementObserver.complete();
    }
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tooltip-template',
  template: `
    <div x-arrow class="tooltip__arrow"></div>
    <div class="tooltip__inner">
      <ng-content></ng-content>
    </div>
  `
})
export class TooltipTemplateComponent extends TooltipTemplateBase implements AfterViewInit {

  @HostBinding('class.tooltipJs') hostClass = true;

  constructor(protected _element: ElementRef) {
    super(_element);
  }

  ngAfterViewInit() {
    // hide tooltip initially
    this._element.nativeElement.classList.add('hidden');
    if (this.elementObserver) {
      this.elementObserver.next(this._element.nativeElement);
      this.elementObserver.complete();
    }
  }
}


// define custom template to avoid css name conflict with bootstrap
const templateHtml = '<div class="tooltipJs {{tooltipClass}}" role="tooltip"><div x-arrow class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tooltip], [tooltipHtml]'
})
export class ToolTipDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() tooltipPlacement = 'auto';

  get tooltip(): string {
    return this._tooltip;
  }

  @Input() set tooltip(val: string) {
    this._tooltip = val;
    this.setTooltip(val);
  }

  get tooltipHtml(): string | TooltipTemplateBase {
    return this._tooltipHtml;
  }

  @Input() set tooltipHtml(val: string | TooltipTemplateBase) {
    this._tooltipHtml = val;
    this.setTooltip(val);
  }

  @Input() tooltipClass: string;
  @Input() tooltipOffset: number | string = 0;
  @Input() tooltipDelay: number | object = {show: 500, hide: 0};
  @Input() tooltipTrigger: string;
  @Input() tooltipContainer: string;
  @Input() escapeWithReference: boolean;
  @Input() tooltipModifiers: any;
  @Input() tooltipDontHideOnChange: boolean;
  @Input() enableWhenOverflow: boolean;
  @Output() tooltipShow = new EventEmitter<void>();

  get enable() {
    return this._enable;
  }

  @Input('tooltipEnable') set enable(val: any) {
    if (this._enable !== !!val) {
      this._enable = !!val;
      if (this._enable) {
        this.createTooltip();
      } else {
        this.destroyTooltip();
      }
    }
  }

  private _enable = true;

  private _tooltip: string;
  private _tooltipHtml: string | TooltipTemplateBase;
  private _tooltipObj: TooltipPopper | null;
  private _initialized: boolean;
  private _isOverflown: boolean;

  private onLayoutChangedBind: EventListener = this.onLayoutChanged.bind(this);
  private layoutChangedSub: any;


  constructor(private element: ElementRef) {
  }

  ngAfterViewInit() {
    this._initialized = true;
    this.setTooltip(this._tooltipHtml || this._tooltip);
    this.layoutChangedSub = EmitterService.getEvent(LAYOUT_CHANGED).subscribe(this.onLayoutChangedBind);
  }

  ngOnDestroy() {
    if (this.layoutChangedSub) {
      this.layoutChangedSub.unsubscribe();
      this.layoutChangedSub = null;
    }
    this.destroyTooltip();
  }

  ngOnChanges() {
    this.setTooltip(this._tooltipHtml || this._tooltip);
  }

  update() {
    if (this._tooltipObj) {
      this._tooltipObj.update();
    }
  }

  show() {
    if (this._tooltipObj) {
      this._tooltipObj.show();
    }
  }

  hide() {
    if (this._tooltipObj) {
      this._tooltipObj.hide();
    }
  }

  checkOverflow() {
    const wasOverFlown = this._isOverflown;
    const isOverFlown = this.isOverflown();
    if (wasOverFlown !== isOverFlown) {
      this.destroyTooltip();
      if (isOverFlown) {
        this.createTooltip();
      }
    }
  }

  private onLayoutChanged(): void {
    this.update();
  }


  private setTooltip(content: string | TooltipTemplateBase) {
    if (!this._initialized) {
      return;
    }
    if (this._tooltipObj) {
      if (!content) {
        this.destroyTooltip();
      } else {
        if (this._tooltipObj) {
          this.getTooltipContent(content).subscribe(tooltipContent => {
            this._tooltipObj?.updateContent(tooltipContent);
          });
        }
      }
    } else {
      if ((!this.enableWhenOverflow || this.isOverflown()) && this._enable && content) {
        this.createTooltip();
      }
    }
  }

  private isOverflown() {
    this._isOverflown = this.element.nativeElement.scrollHeight > this.element.nativeElement.clientHeight || this.element.nativeElement.scrollWidth > this.element.nativeElement.clientWidth;
    return this._isOverflown;
  }

  private createTooltip() {
    if (this.element && this.element.nativeElement) {
      const trigger = this.tooltipTrigger || (this.element.nativeElement.tagName === 'INPUT' ? 'focus' : 'hover');
      const template = templateHtml.replace('{{tooltipClass}}', this.tooltipClass || '');
      const isHtml = !!this.tooltipHtml;
      this.getTooltipContent(this.tooltipHtml || this.tooltip).subscribe(content => {
        if (content) {
          this._tooltipObj = new TooltipPopper(this.element.nativeElement, {content, template, trigger, placement: this.tooltipPlacement, delay: this.tooltipDelay, offset: this.tooltipOffset, escapeWithReference: this.escapeWithReference, modifiers: this.tooltipModifiers, html: isHtml, container: this.tooltipContainer || false, onShow: this.onShow.bind(this)}, this.tooltipDontHideOnChange);
          if (this.tooltipTrigger && this.tooltipTrigger.indexOf('manual') !== -1) {
            setTimeout(() => {
              if (this._tooltipObj) {
                this._tooltipObj.show();
              }
            });
          }
        }
      });
    }
  }

  private onShow() {
    this.tooltipShow.emit();
  }

  private destroyTooltip() {
    if (this._tooltipObj) {
      this._tooltipObj.dispose();
      this._tooltipObj = null;
    }
  }

  private getTooltipContent(content: string | TooltipTemplateBase): Observable<string> {
    if (content instanceof TooltipTemplateBase) {
      return (content as TooltipTemplateBase).element;
    } else {
      return of(content as string);
    }

  }

}
