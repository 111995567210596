import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToolTipDirective, TooltipTemplateComponent, TooltipTemplatePlainComponent } from './toolTipDirective';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ToolTipDirective,
    TooltipTemplateComponent,
    TooltipTemplatePlainComponent,
  ],
  exports: [
        ToolTipDirective,
        TooltipTemplateComponent,
        TooltipTemplatePlainComponent
  ]
})
export class TooltipModule {
}
